module.exports = {
    init: function () {
        let scrollPosition = 0;
        const mediaQuery = window.matchMedia('(min-width: 768px)');

        const triggerOverlay = (overlayId, ingredientCard, elementClicked) => {
            const targetDiv = $('#' + overlayId);
            if (targetDiv.length) {
                scrollPosition = window.scrollY;
                if (scrollPosition > 0) {
                    $('body').css({
                        top: `-${scrollPosition}px`,
                    });
                }
                $('body').addClass('no-scroll');
                targetDiv.toggleClass('d-none active');

                const gtmCustomerNumber = $('.storepage').data('customer');

                const eventData = {
                    click_path: ingredientCard + '|' + elementClicked,
                    customerNumber: gtmCustomerNumber
                };

                // Trigger the GTM event
                $('body').trigger('gtm:recipeOverlay', eventData);
            }
        };

        // Helper function to close the overlay
        const closeOverlay = () => {
            const $overlay = $('.overlay.active');
            if ($overlay.length) {
                $overlay.toggleClass('d-none active');
                $('body').removeClass('no-scroll').css({ top: '' });
                if (scrollPosition > 0) {
                    window.scrollTo(0, scrollPosition);
                }
                scrollPosition = 0; // Reset scroll position tracker
            }
        };

        // Event handler for ingredient card CTA button click
        $(document).on('click', '.cta-button-wrapper button', function (e) {
            const overlayId = $(this).data('overlay-id');
            const ingredientCard = $(this).closest('.ingredient-card').find('.headline')[0].innerText;

            if (overlayId) {
                triggerOverlay(overlayId, ingredientCard, 'Button');
                e.stopPropagation();
            }
        });

        // Event handler for image click within ingredient card
        $(document).on('click', '.ingredient-card .image-wrapper img', function (e) {
            const $ctaButton = $(this).closest('.ingredient-card').find('.cta-button-wrapper button');
            const overlayId = $ctaButton.data('overlay-id');
            const ingredientCard = $(this).closest('.ingredient-card').find('.headline')[0].innerText;

            if (overlayId) {
                triggerOverlay(overlayId, ingredientCard, 'Image');
                e.stopPropagation();
            }
        });

        // Event handler for overlay close button click
        $(document).on('click', '.overlay-inner-content .close-button', function () {
            closeOverlay();
        });

        // Event handler for landing page CTA link click
        $(document).on('click', 'a.btn, button.btn', function () {
            const $clickedButton = $(this);
            const clickedButtonText = $clickedButton[0].innerText;

            // Define sections and their selectors
            const sectionMap = [
                { name: 'overlay', container: $clickedButton.closest('.overlay-content') },
                { name: 'header', container: $clickedButton.closest('.navbar, .nav-icons-content') },
                { name: 'footer', container: $clickedButton.closest('.site-footer') },
                { name: 'main section', container: $('.col-12>.experience-component:not(.experience-commerce_layouts-recipeOverlay)') }
            ];

            // Find the matching section
            const { name: screenSection, container } = sectionMap.find(({ container }) => container.length) || { name: 'main section', container: $('body') };

            // Get all buttons within the identified section that have the same text as the clicked button
            const buttonsInSection = container.find('a.btn, button.btn').filter(function () {
                return $(this).text().trim() === clickedButtonText;
            });

            // Get the index of the clicked button only for main section
            // and for buttons that have at least one duplicate (in terms of text inside the button)
            const buttonIndex = screenSection === 'main section' && buttonsInSection.length > 1 ? buttonsInSection.index($clickedButton) + 1 : null;

            // Get headline if in the overlay section
            const headline = screenSection === 'overlay'
                ? container.find('.headline-wrapper .headline').first().text().trim()
                : '';

            // Get customer number
            const gtmCustomerNumber = $('.storepage').data('customer');

            // Construct the click path
            const headlineComponent = headline ? `${headline}|` : '';
            const indexComponent = buttonIndex ? `|${buttonIndex}` : '';
            const clickPath = `${headlineComponent}${clickedButtonText}${indexComponent}`;

            // Prepare event data
            const eventData = {
                screen_name: 'recipes',
                click_path: clickPath,
                customerNumber: gtmCustomerNumber,
                screen_section: screenSection
            };

            // Trigger the GTM event
            $('body').trigger('gtm:clickCTA', eventData);
        });

        // Event handler for clicking outside the overlay
        $(document).on('click', function (event) {
            const $overlay = $('.overlay.active');
            const $overlayContent = $overlay.find('.overlay-content');

            if ($overlay.length && !$overlayContent.is(event.target) && $overlayContent.has(event.target).length === 0) {
                closeOverlay();
            }
        });

        // Handling dynamic images that change on hover
        $(window).on('load', function () {
            function setupImageHover() {
                $('.dynamic-image').each(function () {
                    const $img = $(this);
                    const hoverImage = $img.data('hover-image');
                    const startImage = $img.data('start-image');

                    const preloadHoverImage = new Image();
                    preloadHoverImage.src = hoverImage;

                    // Change image to hover version on mouseenter
                    $img.on('mouseenter', function () {
                        $img.attr('src', hoverImage);
                    });

                    // Change image back to the original on mouseleave
                    $img.on('mouseleave', function () {
                        $img.attr('src', startImage);
                    });
                });
            }

            function handleMediaQueryChange(mediaQuery) {
                if (mediaQuery.matches) {
                    setupImageHover(); // Apply hover effect on desktop
                } else {
                    // Remove hover event listeners when switching to mobile
                    $('.dynamic-image').off('mouseenter mouseleave');
                }
            }

            handleMediaQueryChange(mediaQuery);

            window.addEventListener('resize', () => {
                handleMediaQueryChange;
            });
        });
    }
};
